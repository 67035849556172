import { captureException } from '@sentry/react';

export enum AutocompleteType {
  FILTER,
  FILTER_OPERATOR,
  VALUE,
  OPERATOR,
}

export interface Group {
  value: string;
  start: number;
  end: number;
}

interface CheckResult {
  matched: string;
  input: string;
}

interface AutocompleteResult {
  isOnlyValue: boolean;
  types: AutocompleteType[];
  matchedValue?: string;
  input: string;
  group: Group;
}

interface MatherConstructorProps {
  text: string;
  operators: string[];
  filters: string[];
  caretPosition: number;
}

interface ValidatorDataItem {
  value?: string;
  operator?: string;
}

type ValidatorData = ValidatorDataItem[];

export const SCREENING_SPECIAL_CHARS = /(\[|\]|\{|\}|\\|\^|\$|\.|\||\+|\*)/g;
export const SCREENING_SPECIAL_CHARS_WITHOUT_DOT = /(\[|\]|\{|\}|\\|\^|\$|\||\+|\*)/g;
export interface QueryValue {
  keys: string[];
  value: string;
  operator: string;
}

export class Matcher {
  text: string;
  operators: string[] = [];
  filters: string[] = [];
  delimiterRegExp = /\sand\s?|\sor\s?/gi;
  group: Group;
  groupCount: number;

  constructor({ text, operators, caretPosition, filters }: MatherConstructorProps) {
    this.text = this.filterGrouping(text);
    this.operators = operators;
    this.filters = filters;
    const groups = this.getGroups(text);
    this.group = this.getCurrentGroup(groups, caretPosition);
    this.groupCount = groups.length;
  }

  filterGrouping(text: string): string {
    return text.replaceAll(/(.*)\((.*)\)(.*)/gi, '$1$2$3');
  }

  getAutocompleteType(): AutocompleteResult {
    const result: AutocompleteResult = {
      isOnlyValue: !!this.group?.value || false,
      types: [],
      matchedValue: Matcher.removeSpecialChars(this.group?.value.trim() || ''),
      group: this.group,
      input: this.group?.value || '',
    };

    const checkValue = this.group ? this.hasValue(this.group) : false;
    if (checkValue) {
      result.isOnlyValue = false;
      result.types.push(AutocompleteType.OPERATOR);
      result.matchedValue = checkValue.matched;
      result.input = checkValue.input;
    } else {
      const checkFilterOperator = this.group ? this.hasFilterOperator(this.group) : false;
      if (checkFilterOperator) {
        result.isOnlyValue = false;
        result.types.push(AutocompleteType.VALUE);
        result.matchedValue = checkFilterOperator.matched;
        result.input = checkFilterOperator.input;
      } else {
        const checkFilter = this.group ? this.hasFilter(this.group) : false;
        if (checkFilter) {
          result.isOnlyValue = false;
          result.types.push(AutocompleteType.FILTER_OPERATOR);
          result.matchedValue = checkFilter.matched;
          result.input = checkFilter.input;
        } else if (this.group) {
          result.types.push(AutocompleteType.FILTER, AutocompleteType.VALUE);
        }
      }
    }

    return result;
  }

  static prepareQuery(params: MatherConstructorProps, query: string, onChange: (value: string) => void) {
    const matcher = new Matcher(params);
    const { isOnlyValue, types } = matcher.getAutocompleteType();

    if (matcher.groupCount === 1 && types.includes(AutocompleteType.VALUE) && isOnlyValue && !!query) {
      onChange(matcher.text.replace(matcher.text, ` ${query} "${matcher.text}"`));
    }
  }

  public getQueryValues(): QueryValue[] {
    const values: QueryValue[] = [];
    const groups = this.getGroups(this.text);

    groups.forEach((group) => {
      const { keys, operator } = this.getKeysFromGroup(group.value);
      const value = this.getValueFromGroup(group.value).replace(/^"(.*)"$/, '$1');
      if (value) {
        values.push({
          keys,
          value,
          operator,
        });
      }
    });

    return values;
  }

  static screenSpecialChars(value: string): string {
    return value.replaceAll(SCREENING_SPECIAL_CHARS, '\\$1').replaceAll(/(\(|\))/g, '\\$1');
  }

  static removeSpecialChars(value: string): string {
    return value.replaceAll(SCREENING_SPECIAL_CHARS_WITHOUT_DOT, '').replaceAll(/(\(|\))/g, '');
  }

  static prepareRegExp(value: string, operator: string): RegExp | string {
    try {
      value = value
        .replaceAll(SCREENING_SPECIAL_CHARS, '\\$1')
        .replaceAll(/\\\*/g, '??')
        .replaceAll(/\*+/g, '.*')
        .replaceAll(/\?{2,}/g, '.*')
        .replaceAll(/\?{1}/g, '.{1}')
        .replaceAll(/\(/g, '\\(?')
        .replaceAll(/\)/g, '\\)?')
        .replaceAll(/"/g, '"?')
        .replaceAll(/(\s)/g, '$1');
      switch (operator) {
        case 'starts_with':
          value = `^${value}`;
          break;
        case 'ends_with':
          value = `${value}$`;
          break;
        default:
          break;
      }

      return new RegExp(value, 'i');
    } catch (error) {
      captureException(error);
      return value;
    }
  }

  public validate(validator: (data: ValidatorData) => boolean): boolean {
    const data: ValidatorData = [];
    const groups = this.getGroups(this.text);

    groups.forEach((group) => {
      const item: ValidatorDataItem = {};
      const value = this.getValueFromGroup(group.value);
      if (value) {
        item.value = value;
      }
      const operator = this.getOperatorFromGroup(group.value);
      if (operator) {
        item.operator = operator;
      }
      data.push(item);
    });

    return validator(data);
  }

  private getKeysFromGroup(text: string): { keys: string[]; operator: string } {
    let keys = '';
    let operator = '';
    const patternRegExp = new RegExp(`(${this.filters.join('|')})\\.(${this.operators.join('|')})(:)(.*)`, 'ig');
    const array = Array.from(text.matchAll(patternRegExp));

    if (array[0]?.[1]) {
      const matchedValue = array[0]?.[1];
      if (matchedValue) {
        keys = matchedValue.trim();
      }
    }

    if (array[0]?.[2]) {
      operator = array[0][2].trim();
    }

    return { keys: keys.split('.'), operator };
  }

  private getValueFromGroup(text: string): string {
    let value = '';

    const patternRegExp = new RegExp(`(${this.filters.join('|')})\\.(${this.operators.join('|')})(:)(.*)`, 'ig');
    const array = Array.from(text.matchAll(patternRegExp));

    if (array[0]?.[4]) {
      const matchedValue = array[0]?.[4];
      if (matchedValue) {
        value = matchedValue.trim();
      }
    }

    return value;
  }

  private getOperatorFromGroup(text: string): string {
    let value = '';

    const patternRegExp = new RegExp(`(${this.filters.join('|')})\\.(${this.operators.join('|')})(:?)`, 'ig');
    const array = Array.from(text.matchAll(patternRegExp));

    if (array[0]?.[2]) {
      const matchedValue = array[0]?.[2];
      if (matchedValue) {
        value = matchedValue.trim();
      }
    }

    return value;
  }

  private getGroups(text: string): Group[] {
    const delimiters = this.getDelimiters(text);

    return text.split(this.delimiterRegExp).map((group, index) => {
      let startIndex = 0;
      if (index !== 0) {
        const delimiter = delimiters.next();
        startIndex = delimiter.value.index + delimiter.value[0].length;
      }
      return {
        value: group,
        start: startIndex,
        end: startIndex + group.length,
      };
    });
  }

  private getDelimiters(text: string) {
    return text.matchAll(this.delimiterRegExp);
  }

  private getCurrentGroup(groups: Group[], position: number) {
    return groups.find(({ start, end }) => position >= start && position <= end) as Group;
  }

  private hasValue(group: Group): CheckResult | false {
    const groupText = group.value;
    const patternRegExp = new RegExp(
      `(${this.filters.join('|')})\\.(${this.operators.join('|')})(:)(\\s?"?([\\wа-яА-ЯЁё]|[*?"])+"?)`,
      'ig'
    );
    const array = Array.from(groupText.matchAll(patternRegExp));
    return array.length > 0
      ? {
          matched: array?.[0]?.[4],
          input: array?.[0]?.input || '',
        }
      : false;
  }

  private hasFilterOperator(group: Group): CheckResult | false {
    const groupText = group.value;
    const patternRegExp = new RegExp(`(${this.filters.join('|')})\\.(${this.operators.join('|')})(:?)`, 'ig');
    const array = Array.from(groupText.matchAll(patternRegExp));

    return array.length > 0
      ? {
          matched: array?.[0]?.[2],
          input: array?.[0]?.input || '',
        }
      : false;
  }

  private hasFilter(group: Group): CheckResult | false {
    const groupText = group.value;
    const patternRegExp = new RegExp(`(${this.filters.join('|')})\\.`, 'ig');
    const array = Array.from(groupText.matchAll(patternRegExp));

    return array.length > 0
      ? {
          matched: array?.[0]?.[1],
          input: array?.[0]?.input || '',
        }
      : false;
  }
}
